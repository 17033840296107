<template>
	<div class="main-content">
		<b-row>
			<b-col>
				<b-list-group>
					<b-list-group-item style="border-top: none; border-bottom: none">
						<b-row>
							<b-col class="text-center">
								<img :src="require('@/assets/images/LogoKoltiva.png')" style="width: 180px; height: 50px" />
							</b-col>
						</b-row>
					</b-list-group-item>
					<b-list-group-item style="border-top: none; border-bottom: none">
						<b-row align-v="center">
							<b-col>
								<span class="text-30 font-weight-bold">{{ $t("Invoice", "Invoice") }}</span>
								<br />
								{{ disburseDetail.dateSubmitted ? invoiceDate(disburseDetail.dateSubmitted) : "-" }}
							</b-col>
							<b-col class="text-right">
								<span class="text-15 font-weight-bold">PT Koltiva</span><br />
								RA Mampang Tower, South Jakarta, 12790, Indonesia<br />
								<span class="text-12 font-weight-bold">{{ $t("Phone", "Phone") }}:</span>
								(+62) 21-3970 2013
								<span class="text-12 font-weight-bold pl-2">{{ $t("Email", "Email") }}:</span>
								info@koltiva.com
							</b-col>
						</b-row>
					</b-list-group-item>
					<b-list-group-item>
						<b-row align-v="center">
							<b-col>
								{{ $t("Issue Date", "Issue Date") }}:<span class="text-12 font-weight-bold"> {{ issueDate }}</span>
							</b-col>
							<b-col class="text-right">
								{{ $t("Due Date", "Due Date") }}:<span class="text-12 font-weight-bold"> {{ dueDate }}</span>
							</b-col>
						</b-row>
					</b-list-group-item>
					<b-list-group-item style="border-bottom: none; border-top: none">
						<b-row align-v="center">
							<b-col cols="4">
								<b-row>
									<b-col cols="4">{{ $t("Bill To", "Bill To") }}:</b-col>
									<b-col cols="8">
										<div class="text-12 font-weight-bold pl-2">PT. MARS Symbioscience Indonesia</div>
										<div class="text-12 pl-2">Jl. Kima 10 Kav A6, Kota Makassar, Indonesia 90241</div>
									</b-col>
								</b-row>
							</b-col>
							<b-col cols="1" />
							<b-col cols="4">
								<b-row>
									<b-col cols="4">{{ $t("Recipient", "Recipient") }}:</b-col>
									<b-col cols="8">
										<div class="text-12 font-weight-bold pl-2">PT. Koltiva</div>
										<div class="text-12 pl-2">RA Mampang Tower, South Jakarta, 12790, Indonesia</div>
									</b-col>
								</b-row>
							</b-col>
							<b-col cols="3" />
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col class="mt-4">
				<b-list-group>
					<b-list-group-item class="text-13">
						<b-row>
							<b-col cols="5">
								<b>{{ $t("Description", "Description").toUpperCase() }}</b>
							</b-col>
							<b-col class="text-right">
								<b>{{ $t("Amount", "Amount").toUpperCase() }}</b>
							</b-col>
							<b-col class="text-right">
								<b>{{ $t("Qty", "Qty").toUpperCase() }}</b>
							</b-col>
							<b-col class="text-right">
								<b>{{ $t("Subtotal", "Subtotal").toUpperCase() }}</b>
							</b-col>
						</b-row>
					</b-list-group-item>
					<b-list-group-item class="text-12">
						<b-row>
							<b-col cols="5">{{ $t("Premium Points Disburse Fee", "Premium Points Disburse Fee") }}</b-col>
							<b-col class="text-right">{{ Math.round(disburseDetail.subTotalFee) | Rupiah }}</b-col>
							<b-col class="text-right">1</b-col>
							<b-col class="text-right">{{ Math.round(disburseDetail.subTotalFee) | Rupiah }}</b-col>
						</b-row>
					</b-list-group-item>
					<b-list-group-item class="text-12 mt-3" style="border-top: none; border-bottom: none">
						<b-row>
							<b-col cols="8">
								{{ $t("Payment Status", "Payment Status") }}:
								<b>
									{{ disburseDetail.statusDisburseDescription ? disburseDetail.statusDisburseDescription : "-" }}
								</b>
							</b-col>
							<b-col>{{ $t("Subtotal", "Subtotal") }}</b-col>
							<b-col class="text-right">{{ Math.round(disburseDetail.subTotalFee) | Rupiah }}</b-col>
						</b-row>
						<b-row>
							<b-col cols="8" />
							<b-col>{{ `${$t("VAT", "VAT")} (11%)` }}</b-col>
							<b-col class="text-right">+{{ disburseDetail.vatFee | Rupiah }}</b-col>
						</b-row>
						<b-row>
							<b-col cols="8" />
							<b-col>{{ $t("Total", "Total") }}</b-col>
							<b-col class="text-right">{{ Math.round(disburseDetail.subTotalFee + disburseDetail.vatFee) | Rupiah }}</b-col>
						</b-row>
						<b-row>
							<b-col cols="8" />
							<b-col>{{ `${$t("Art 23", "Art 23")} (2%)` }}</b-col>
							<b-col class="text-right">-{{ disburseDetail.whtArt23Fee | Rupiah }}</b-col>
						</b-row>
					</b-list-group-item>
					<b-list-group-item class="text-12 text-white" style="border-top: none">
						<b-row>
							<b-col cols="8" />
							<b-col class="p-2 ml-3" style="background: #000">{{ $t("Total Payment", "Total Payment") }}</b-col>
							<b-col class="text-right p-2 mr-3" style="background: #000">
								{{ Math.round(disburseDetail.totalPremiumFee) | Rupiah }}
							</b-col>
						</b-row>
					</b-list-group-item>
					<b-list-group-item style="border-bottom: none" class="text-11">
						<b-row>
							<b-col>{{ $t("Thank you for your business", "Thank you for your business") }}</b-col>
							<b-col class="text-right">PT Koltiva</b-col>
						</b-row>
					</b-list-group-item>
				</b-list-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
	export default {
		name: "PremiumPointsDisburseRequestInvoice",
		props: {
			disburseDetail: {
				default: () => {},
				required: true,
				type: Object,
			},
			disburseId: {
				default: "",
				required: true,
				type: [Number, String],
			},
			payment: {
				default: () => {},
				required: true,
				type: Object,
			},
		},
		data() {
			return {
				dueDate: this.getDate(),
				issueDate: this.getDate(),
			}
		},
		methods: {
			getDate() {
				const today = new Date() // TO-DO: to be changed
				const date = today.getDate()
				const month = today.getMonth() + 1 // Months are zero based
				const year = today.getFullYear()

				return `${date}/${month}/${year}`
			},
			invoiceDate() {
				const date = new Date(this.disburseDetail.dateSubmitted)

				return `KTV-INV-PYMDSB-${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${this.disburseId}`
			},
		},
	}
</script>

<style>
	.list-group-item {
		border-left: none;
		border-right: none;
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	}
</style>
